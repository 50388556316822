import { useEffect, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import { ProgressTheme } from "../../utils/MuiThemes";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";

import "./Loading.scss";

function Loading() {
  const [progress, setProgress] = useState(0);

  const { t } = useTranslation(["components"]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 30;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="loadContainer">
      <img
        src="/assets/images/logo64small.png"
        alt="logo"
        className="loadLogo"
      />
      <img src="/assets/images/name.png" alt="name" className="loadName" />

      <p>{t("feedbackInter")}</p>
      <ThemeProvider theme={ProgressTheme}>
        <div>
          <LinearProgress
            className="loadLoading"
            variant="determinate"
            value={progress}
          />
          <p className="loadLoadingText">{t("loading")}</p>
        </div>
      </ThemeProvider>
      {/* <img
        src="/assets/images/asset_landing_image.svg"
        alt="landing"
        className="loadLandingImg"
      /> */}
    </div>
  );
}

export default Loading;
