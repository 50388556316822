import { useContext, useState, useRef } from "react";

import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";

import "./EditPostTopic.scss";
import axios from "../../utils/Axios";
import Divider from "../Divider/Divider";
import { Context } from "../../utils/Context";
import { AnonContext } from "../AnonymousTabs/State";
import { PubContext } from "../PublicTabs/State";
import { PriContext } from "../PrivateTabs/State";
import { PerContext } from "../PersonalTopics/State";
import { FgContext } from "../Flagged/State";
import { LkContext } from "../Liked/State";
import { SvContext } from "../Saved/State";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { canEditText } from "../../utils/TimeFormatter";
import { useScrollToSection } from "../../utils/Effects";
import { DefaultForumContext } from "../ForumDefault/State";
import { ForumContext } from "../Forum/State";

function EditPostTopic({
  title,
  func,
  topicTable,
  editInx,
  noAtt,
  usrId,
  showInput,
  lDispatch,
  spDispatch,
  defaultText,
  isTabDirector,
  time,
}) {
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [topicCat, setTopicCat] = useState("private");
  const dragImage = useRef(null);
  const dragOverImage = useRef(null);
  const tpTopicBoxRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["components", "extras"]);

  const { state, dispatch } = useContext(Context);
  const { anonDispatch } = useContext(AnonContext);
  const { pubDispatch } = useContext(PubContext);
  const { priDispatch } = useContext(PriContext);
  const { perDispatch } = useContext(PerContext);
  const { fgDispatch } = useContext(FgContext);
  const { lkDispatch } = useContext(LkContext);
  const { svDispatch } = useContext(SvContext);
  const { defaultForumDispatch } = useContext(DefaultForumContext);
  const { forumDispatch } = useContext(ForumContext);
  const { userInfo, isDirector } = state;
  const { register, handleSubmit } = useForm();

  const tkn = localStorage.getItem("tkn");

  useScrollToSection(tpTopicBoxRef, "center");

  const handleImageChange = (e) => {
    const images = e.target.files;
    let imageArr = [];
    if (images.length > 5) {
      setImage([]);
      enqueueSnackbar("maximum of 5 files can be uploaded", {
        variant: "error",
      });
    } else {
      for (let i = 0; i <= images.length; i++) {
        imageArr.push(images[i]);
      }
      setImage(imageArr);
    }
  };
  const handleImageSort = () => {
    let _images = [...image];
    const draggedImage = _images.splice(dragImage.current, 1)[0];
    _images.splice(dragOverImage.current, 0, draggedImage);
    dragImage.current = null;
    dragOverImage.current = null;
    setImage(_images);
  };

  const handleTopicCatChange = (e) => {
    setTopicCat(e.target.value);
  };

  const hideTopicSetting = () => {
    if (typeof dispatch === "function") {
      dispatch({ type: "HIDE_SETTING" });
    }
  };

  const handleEditPost = async ({ newTopicText }) => {
    if (newTopicText === "") {
      enqueueSnackbar("Empty post not allowed!", { variant: "error" });
      return;
    }
    const data = new FormData();
    data.append("text", newTopicText);
    data.append("tab", topicTable);
    data.append("cat", topicCat);
    data.append("edit", editInx);
    for (let i = 0; i < image.length; i++) {
      data.append("attch", image[i]);
    }

    const url = "/addt";
    const options = {
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
        authorization: `${tkn}`,
      },
      data,
      url,
    };
    try {
      setLoading(true);
      const { data } = await axios(options);
      setLoading(false);
      if (data?.status === false) {
        enqueueSnackbar(data?.msg, { variant: "error" });
      }

      hideTopicSetting();

      if (typeof anonDispatch === "function") {
        anonDispatch({
          type: "UPDATE_TOPIC_STATUS",
          payload: {
            tId: editInx,
            content: data?.new_cmnt.content,
            status: {
              public: data?.new_cmnt.settings[0],
              edited: data?.new_cmnt.edited,
            },
          },
        });
      }

      if (typeof pubDispatch === "function") {
        pubDispatch({
          type: "UPDATE_TOPIC_STATUS",
          payload: {
            tId: editInx,
            content: data?.new_cmnt.content,
            status: {
              public: data?.new_cmnt.settings[0],
              edited: data?.new_cmnt.edited,
            },
          },
        });
      }

      if (typeof priDispatch === "function") {
        priDispatch({
          type: "UPDATE_TOPIC_STATUS",
          payload: {
            tId: editInx,
            content: data?.new_cmnt.content,
            status: {
              public: data?.new_cmnt.settings[0],
              edited: data?.new_cmnt.edited,
            },
          },
        });
      }

      if (typeof perDispatch === "function") {
        perDispatch({
          type: "UPDATE_TOPIC_STATUS",
          payload: {
            tId: editInx,
            content: data?.new_cmnt.content,
            status: {
              public: data?.new_cmnt.settings[0],
              edited: data?.new_cmnt.edited,
            },
          },
        });
      }

      if (typeof fgDispatch === "function" && editInx !== "0") {
        fgDispatch({
          type: "UPDATE_TOPIC_STATUS",
          payload: {
            tId: editInx,
            content: data?.new_cmnt.content,
            status: {
              public: data?.new_cmnt.settings[0],
              edited: data?.new_cmnt.edited,
            },
          },
        });
      }

      if (typeof lkDispatch === "function" && editInx !== "0") {
        lkDispatch({
          type: "UPDATE_TOPIC_STATUS",
          payload: {
            tId: editInx,
            content: data?.new_cmnt.content,
            status: {
              public: data?.new_cmnt.settings[0],
              edited: data?.new_cmnt.edited,
            },
          },
        });
      }

      if (typeof svDispatch === "function" && editInx !== "0") {
        svDispatch({
          type: "UPDATE_TOPIC_STATUS",
          payload: {
            tId: editInx,
            content: data?.new_cmnt.content,
            status: {
              public: data?.new_cmnt.settings[0],
              edited: data?.new_cmnt.edited,
            },
          },
        });
      }

      if (typeof defaultForumDispatch === "function" && editInx !== "0") {
        defaultForumDispatch({
          type: "UPDATE_TOPIC_STATUS",
          payload: {
            tId: editInx,
            content: data?.new_cmnt.content,
            status: {
              public: data?.new_cmnt.settings[0],
              edited: data?.new_cmnt.edited,
            },
          },
        });
      }

      if (typeof forumDispatch === "function" && editInx !== "0") {
        forumDispatch({
          type: "UPDATE_TOPIC_STATUS",
          payload: {
            tId: editInx,
            content: data?.new_cmnt.content,
            status: {
              public: data?.new_cmnt.settings[0],
              edited: data?.new_cmnt.edited,
            },
          },
        });
      }

      if (typeof lDispatch === "function" && editInx !== "0") {
        lDispatch({
          type: "UPDATE_TOPIC_STATUS",
          payload: {
            tId: editInx,
            content: data?.new_cmnt.content,
            status: {
              public: data?.new_cmnt.settings[0],
              edited: data?.new_cmnt.edited,
            },
          },
        });
      }

      if (spDispatch !== undefined && typeof spDispatch === "function") {
        spDispatch({
          type: "UPDATE_TOPIC_TITLE",
          payload: { topicId: editInx, newTopic: data?.new_cmnt.content },
        });
        spDispatch({
          type: "UPDATE_TAB_PUBLIC_TOPIC",
          payload: data?.new_cmnt?.settings[0],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={"eptEditPost"}>
      <div className="eptEditTopic">{title}</div>
      <form
        ref={tpTopicBoxRef}
        onSubmit={handleSubmit(handleEditPost)}
        encType="multipart/form-data"
      >
        <div
          className={
            !(userInfo?.idd === usrId && canEditText(time))
              ? "eptInsideForm"
              : "eptInsideForm eptInsideFormExtend"
          }
        >
          {((userInfo?.idd === usrId && canEditText(time)) || showInput) && (
            <textarea
              {...register("newTopicText")}
              cols="30"
              rows="6"
              defaultValue={defaultText}
              className="eptTextarea"
              style={{ width: "100%" }}
            />
          )}

          <div style={{ width: "100%" }}>
            <div className={noAtt ? "eptRadioContainer" : ""}>
              {(isDirector === true || isTabDirector === true) && (
                <div className="eptRadioLabel">
                  <input
                    type="radio"
                    name="topicCat"
                    id="public"
                    value="public"
                    checked={topicCat === "public"}
                    onChange={handleTopicCatChange}
                    className="eptRadio"
                  />
                  <label htmlFor="public">{t("components:public")}</label>
                </div>
              )}
              <div className="eptRadioLabel">
                <input
                  type="radio"
                  name="topicCat"
                  id="private"
                  value="private"
                  checked={topicCat === "private"}
                  onChange={handleTopicCatChange}
                  className="eptRadio"
                />
                <label htmlFor="private">{t("components:private")}</label>
              </div>
            </div>
            <div className={noAtt ? "eptBtn eptBtnExtend" : "eptBtn"}>
              {noAtt ? (
                ""
              ) : (
                <div>
                  <label htmlFor="attchxx" className="eptLabel">
                    {t("extras:btnAttachImg")}
                  </label>
                  <input
                    id="attchxx"
                    name="attchxx"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    multiple
                    onChange={(e) => {
                      handleImageChange(e);
                    }}
                    className="eptFile"
                  />
                </div>
              )}

              <button type="submit">
                {loading ? (
                  <>
                    {t("extras:btnPosting")}
                    <CircularProgress size={15} className="btnProgress" />
                  </>
                ) : (
                  func
                )}
              </button>
              <button type="button" onClick={hideTopicSetting}>
                {t("extras:btnCancel")}
              </button>
            </div>
          </div>
        </div>
      </form>

      {image.length >= 1 && image.length <= 5 && (
        <div className="eptPreviewContainer">
          <div className="eptPreviewInfo">{t("components:attachHint")}</div>

          {image.length > 2 && image.length <= 5 && (
            <>
              <div className="eptDivider">
                <Divider />
              </div>
              <div className="eptPreviewInfo">{t("components:dragOrder")}</div>
            </>
          )}
          <div className="eptPreviewNameContainer">
            {image.map((image, index) => {
              return (
                <div
                  key={index}
                  draggable
                  className="eptDrag"
                  onDragStart={() => (dragImage.current = index)}
                  onDragEnter={() => (dragOverImage.current = index)}
                  onDragEnd={handleImageSort}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <Divider />
                  <div className="eptPreviewName">{image?.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default EditPostTopic;
