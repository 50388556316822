export const RenderContent = (contentArray, uid, type) => {
  const result = contentArray?.map((content, index) => (
    <div key={index}>
      {content}
      {type === "topic" && contentArray?.length - 1 === index && uid}
    </div>
  ));

  return result;
};
