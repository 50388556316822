import { useContext, useEffect, useRef, useState } from "react";

import "./ModifyList.scss";
import Close from "@mui/icons-material/Close";
import axios from "../../utils/Axios";

import { Context } from "../../utils/Context";
import { CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Divider from "../Divider/Divider";
import { useClickOut } from "../../utils/Effects";

function ModifyList() {
  const [participants, setParticipants] = useState([]);
  const [memValue, setMemValue] = useState([]);
  const [openMembers, setOpenMembers] = useState(false);
  const [openDirectors, setOpenDirectors] = useState(false);
  const [showMembersSearchPanel, setShowMembersSearchPanel] = useState(false);
  const [showDirectorsSearchPanel, setShowDirectorsSearchPanel] =
    useState(false);
  const [dirValue, setDirValue] = useState([]);
  const [dir, setDir] = useState([]);
  const [inputMember, setInputMember] = useState();
  const [inputDirector, setInputDirector] = useState();
  const [startModify, setStartModify] = useState(false);
  const { state, dispatch } = useContext(Context);
  const { currentTabName, currentTabId, tabSettings } = state;
  const [fileName, setFileName] = useState("No file chosen");

  const fileRef = useRef();
  const memberSearchRef = useRef();
  const directorSearchRef = useRef();
  const memberSearchInputRef = useRef();
  const directorSearchInputRef = useRef();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["components", "extras"]);
  const tkn = localStorage.getItem("tkn");
  let fileReader;

  const { handleSubmit, register } = useForm();

  const fetchUsers = async () => {
    const url = "/show";
    const formData = new FormData();
    formData.append("tab", currentTabName);
    const options = {
      method: "POST",
      headers: {
        authorization: tkn,
        "content-type": "application/x-www-form-urlencoded",
      },
      data: formData,
      url,
    };

    const { data } = await axios(options);

    if (data?.status) {
      setParticipants(data?.tab_membs);
      setMemValue(data?.tab_membs);
      setDir(data?.tab_dirs);
      setDirValue(data?.tab_dirs);
    }
  };

  const removeMember = (member) => {
    const filteredPeople = participants.filter((pa) => pa !== member);
    setParticipants(filteredPeople);
  };

  const removeDirector = (direct) => {
    const filteredDir = dir?.filter((dr) => dr !== direct);
    setDir(filteredDir);
  };

  const addInputMember = () => {
    const remArr = inputMember.split(/,\s*|\s+/).filter(Boolean);
    const newMember = remArr.filter((rm) =>
      participants.every((mb) => rm !== mb)
    );
    if (newMember.length !== 0) {
      setParticipants([...participants, ...newMember]);
    }
  };

  const addInputDirector = () => {
    const dirArr = inputDirector.split(/,\s*|\s+/).filter(Boolean);
    const newDirector = dirArr.filter((dr) => dir.every((dt) => dt !== dr));
    if (newDirector.length !== 0) {
      setDir([...dir, ...newDirector]);
    }
  };

  const handleFileRead = (e) => {
    const content = fileReader.result;
    const remArr = content.split(/,\s*|\s+/).filter(Boolean);
    setParticipants(participants.concat(remArr));
  };

  const handleFileChosen = (file) => {
    setFileName(file.name);
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  const handleSearch = (searchWord) => {
    const filteredData = memValue?.filter((pt) => pt.includes(searchWord));
    setParticipants(filteredData);
  };

  const handleSearchDir = (searchWord) => {
    const filteredData = dirValue?.filter((dr) => dr.includes(searchWord));
    setDir(filteredData);
  };

  const modifyTab = async ({ mT_PwCk }) => {
    if (mT_PwCk === "") {
      enqueueSnackbar("Password cannot be empty", { variant: "error" });
      return;
    }
    const url = "/modtab";
    const formData = new FormData();
    formData.append("pass", mT_PwCk);
    formData.append("id", currentTabId);
    formData.append("list_d", dir);
    formData.append("list_m", participants);
    const options = {
      method: "POST",
      headers: {
        authorization: tkn,
        "content-type": "application/x-www-form-urlencoded",
      },
      data: formData,
      url,
    };
    setStartModify(true);
    const { data } = await axios(options);
    setStartModify(false);
    enqueueSnackbar(data.status === true ? "Changes saved" : data.msg, {
      variant: data.status === true ? "success" : "error",
    });
    fetchUsers();
    if (data.status === true) {
      dispatch({ type: "TAB_MODIFIED" });
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  useEffect(() => {
    if (participants.length === 0) {
      fileRef.current.value = null;
    }
  }, [participants.length]);

  useClickOut({
    onState: showMembersSearchPanel,
    mainRef: memberSearchRef,
    subRef: memberSearchInputRef,
    setFunc: setShowMembersSearchPanel,
  });

  useClickOut({
    onState: showDirectorsSearchPanel,
    mainRef: directorSearchRef,
    subRef: directorSearchInputRef,
    setFunc: setShowDirectorsSearchPanel,
  });
  return (
    <>
      <div
        className={
          tabSettings?.includes("mod") ? "modBody" : "modBody modBodyCol"
        }
      >
        <div style={{ marginBottom: "15px", padding: "0 20px" }}>
          <Divider />
        </div>
        <form onSubmit={handleSubmit(modifyTab)}>
          <div className="fileList">
            <div className="modShareHint">
              {t("components:use")}{" "}
              <span
                className="modShareLink"
                onClick={() =>
                  dispatch({
                    type: "SET_SHOW_CREATE_INVITE",
                    payload: true,
                  })
                }
              >
                {t("components:shareInviteLink")}
              </span>{" "}
              <span>{t("components:toCreateALink")}</span>
            </div>
            <div style={{ marginTop: "8px" }}>{t("components:orUpload")}</div>
            <div
              style={{
                marginBottom: "15px",
                marginTop: "15px",
                padding: "0 20px",
              }}
            >
              <Divider />
            </div>
            <div className="modFile">
              <label htmlFor="participants" className="fileLabel">
                Upload List | {fileName}
              </label>
              <input
                id="participants"
                type="file"
                accept=".txt"
                ref={fileRef}
                onChange={(e) => handleFileChosen(e.target.files[0])}
              />
            </div>
            <div className="modMembers">
              <div>
                {t("components:totalOf")} {participants?.length}{" "}
                {participants?.length > 1
                  ? t("components:Members")
                  : t("components:Member")}
              </div>
              <section className="modSearchAndArrow">
                {/* Search */}
                <div className="modSearch" ref={memberSearchRef}>
                  <div
                    className={
                      showMembersSearchPanel
                        ? "modInputContainerEx"
                        : "modInputContainer"
                    }
                    onClick={() => setShowMembersSearchPanel(true)}
                  >
                    <img src="/assets/images/search.svg" alt="search-icon" />
                    <input
                      type="text"
                      placeholder={t("components:tpSearchPh")}
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                      className={
                        showMembersSearchPanel ? "modInputEx" : "modInput"
                      }
                      ref={memberSearchInputRef}
                    />
                  </div>
                </div>
                <div
                  className="arrowCont"
                  onClick={() => setOpenMembers(!openMembers)}
                >
                  <img
                    src="/assets/images/forwardArrow.png"
                    alt=""
                    className={
                      openMembers ? "modCardArrowRotate" : "modCardArrow "
                    }
                  />
                </div>
              </section>
            </div>
            <section className={openMembers ? "openSection" : "hideSection"}>
              <div className="modMemberList">
                {participants?.map((member, index) => {
                  return (
                    <div key={index}>
                      {member}
                      <Close
                        className="modIcon"
                        onClick={() => removeMember(member)}
                      />
                    </div>
                  );
                })}
              </div>
              <div style={{ marginTop: "10px" }}>
                {t("components:orManually")}
              </div>
              <div className="modFields">
                <textarea
                  className="modInput"
                  placeholder={t("components:catPhAddMore")}
                  onChange={(e) => setInputMember(e.target.value)}
                />

                <div className="modBtnCont">
                  <button
                    type="button"
                    onClick={addInputMember}
                    className="modBtn"
                  >
                    {t("extras:btnAddEditList")}
                  </button>
                </div>
              </div>
              <div className="modInfo">{t("components:tpMembersHint")}</div>
            </section>
            <div
              style={{
                marginBottom: "15px",
                marginTop: "15px",
                padding: "0 45px",
              }}
            >
              <Divider />
            </div>
            {/* Directors */}
            <div className="modMembers">
              <div style={{ paddingRight: dir?.length > 1 ? "4px" : "8.5px" }}>
                {t("components:totalOf")} {dir?.length}{" "}
                {dir?.length > 1
                  ? t("components:Directors")
                  : t("components:Director")}
              </div>
              <section className="modSearchAndArrow">
                {/* Search */}
                <div className="modSearch" ref={directorSearchRef}>
                  <div
                    className={
                      showDirectorsSearchPanel
                        ? "modInputContainerEx"
                        : "modInputContainer"
                    }
                    onClick={() => setShowDirectorsSearchPanel(true)}
                  >
                    <img src="/assets/images/search.svg" alt="search-icon" />
                    <input
                      type="text"
                      placeholder={t("components:tpSearchPh")}
                      onChange={(e) => {
                        handleSearchDir(e.target.value);
                      }}
                      className={
                        showDirectorsSearchPanel ? "modInputEx" : "modInput"
                      }
                      ref={directorSearchInputRef}
                    />
                  </div>
                </div>
                <div
                  className="arrowCont"
                  onClick={() => setOpenDirectors(!openDirectors)}
                >
                  <img
                    src="/assets/images/forwardArrow.png"
                    alt=""
                    className={
                      openDirectors ? "modCardArrowRotate" : "modCardArrow "
                    }
                  />
                </div>
              </section>
            </div>
            <section className={openDirectors ? "openSection" : "hideSection"}>
              <div className="modMemberList">
                {dir?.map((direct, index) => {
                  return (
                    <div key={index}>
                      {direct}
                      <Close
                        className="modIcon"
                        onClick={() => removeDirector(direct)}
                      />
                    </div>
                  );
                })}
              </div>
              <div style={{ marginTop: "10px" }}>
                {t("components:orManually")}
              </div>
              <div className="modFields">
                <textarea
                  className="modInput"
                  placeholder={t("components:catPhAddMore")}
                  onChange={(e) => setInputDirector(e.target.value)}
                />

                <div className="modBtnCont">
                  <button
                    type="button"
                    onClick={addInputDirector}
                    className="modBtn"
                  >
                    {t("extras:btnAddEditList")}
                  </button>
                </div>
              </div>
              <div className="modInfo">{t("components:tpDirectorsHint")}</div>
            </section>
            <div
              style={{
                marginBottom: "15px",
                marginTop: "15px",
                padding: "0 45px",
              }}
            >
              <Divider />
            </div>
            <div className="modFields">
              <div className="passFieldWrapper">
                <label className="tsLabels" style={{ marginTop: "20px" }}>
                  {t("extras:btnSave")} <span className="astTs">*</span>
                </label>
                <div className="passFieldWrapper">
                  <input
                    name="usrPassChk"
                    type="password"
                    placeholder={t("components:enterCurrentPass")}
                    className="passInput"
                    {...register("mT_PwCk")}
                  />
                </div>
              </div>
              <div className="modBtnCont">
                <button type="submit" className="modBtn">
                  {t("extras:btnSave")}
                  {startModify && (
                    <CircularProgress size={15} className="btnProgress" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default ModifyList;
