import { useContext, useEffect, useState } from "react";
import "./TipsCard.scss";
import { Context } from "../../utils/Context";
const TipsCard = ({ data }) => {
  const [step, setStep] = useState(0);

  const handleNext = () => {
    if (step === data?.length - 1) {
      setStep(0);
    } else {
      setStep((prev) => prev + 1);
    }
  };

  useEffect(() => {
    dispatch({ type: "SET_TIP_STEP", payload: step });
  }, [step]);

  const { dispatch } = useContext(Context);
  return (
    <div
      className="tipsBgArrowContainer"
      style={{ marginBottom: step === data?.desc?.length - 1 ? "100px" : "" }}
    >
      {/* <img src="/assets/images/bgArrow.png" alt="" className="tipsBgArow" /> */}
      <div className="tipsBgArrow"></div>
      <section className="tipsCardContainer">
        <div className="tipsTitle">{data[step]?.title}</div>
        <section className="tipsPointer">{data[step]?.pointer}</section>
        <section className="tipsDesc">{data[step]?.desc}</section>
        <section className="tipsAction">
          <button
            className="tipsBtnDismiss"
            onClick={() => dispatch({ type: "HIDE_TIPS" })}
          >
            Dismiss
          </button>
          <span className="tipsCount">
            {step + 1} of {data?.length}
          </span>
          <button className="tipsBtnNext" onClick={handleNext}>
            Next
          </button>
        </section>
      </section>
    </div>
  );
};

export default TipsCard;
