import { useEffect, useContext, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "./Axios";
import { useSnackbar } from "notistack";
import { Context } from "./Context";
import { notifyBaseUrl } from "./Base";

//Fetch user Tabs
export const GetUserTabs = () => {
  const { dispatch, state } = useContext(Context);
  const navigate = useNavigate();
  const {
    newTabAlert,
    deleteTabAlert,
    tabAcceptAlert,
    verifyStatus,
    emailChange,
    modTab,
    openTab,
  } = state;

  useEffect(() => {
    const tkn = localStorage.getItem("tkn");

    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };
    const getUsertabs = async () => {
      dispatch({ type: "START_TABS_LOADING" });
      const { data } = await axios.get("/ut", config);
      dispatch({ type: "STOP_TABS_LOADING" });
      if (data?.status) {
        dispatch({ type: "SET_USER_TABS", payload: data });
        dispatch({ type: "USER_LOGIN", payload: data?.usr });
        localStorage.setItem("userInfo", JSON.stringify(data?.usr));
      } else {
        dispatch({ type: "USER_LOGOUT" });
        navigate("/");
      }
    };
    getUsertabs();
  }, [
    newTabAlert,
    deleteTabAlert,
    tabAcceptAlert,
    verifyStatus,
    emailChange,
    modTab,
    openTab,
  ]);
};
//Fetch user controlled Tabs
export const GetCreated = () => {
  const { dispatch, state } = useContext(Context);
  const { newTabAlert, tabAcceptAlert, emailChange, modTab, tabSettingsDone } =
    state;
  const navigate = useNavigate();

  useEffect(() => {
    const tkn = localStorage.getItem("tkn");
    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };
    const getCreated = async () => {
      dispatch({ type: "START_TABS_LOADING" });
      const { data } = await axios.get("/crtd", config);
      dispatch({ type: "STOP_TABS_LOADING" });
      if (data?.status) {
        dispatch({
          type: "SET_USER_TABS_CREATED",
          payload: data?.tabs_created?.sort((a, b) => b.tab_id - a.tab_id),
        });
      } else {
        dispatch({ type: "USER_LOGOUT" });
        navigate("/");
      }
    };
    getCreated();
  }, [newTabAlert, tabAcceptAlert, emailChange, modTab, tabSettingsDone]);
};
//Fetch Tabs user is participating
export const GetVerified = () => {
  const { dispatch, state } = useContext(Context);
  const { verifyStatus, emailChange, modTab, tabSettingsDone, forumAction } =
    state;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const tkn = localStorage.getItem("tkn");
    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };
    const getVerified = async () => {
      dispatch({ type: "START_TABS_LOADING" });
      const { data } = await axios.get("/vrfd", config);
      dispatch({ type: "STOP_TABS_LOADING" });
      if (data?.status) {
        dispatch({
          type: "SET_USER_TABS_VERIFIED",
          payload: data?.verified
            ?.sort((a, b) => {
              return new Date(b?.tab_latest) - new Date(a?.tab_latest);
            })
            .sort((a, b) => {
              if (a.forum_start === null && b.forum_start !== null) {
                return 1; // a should come after b
              } else if (a.forum_start !== null && b.forum_start === null) {
                return -1; // a should come before b
              } else {
                return 0; // keep original order if both are null or both are not null
              }
            }),
        });
      } else {
        if (
          location.pathname.split("/")[1] === "topics" ||
          location.pathname.split("/")[1] === "topic" ||
          location.pathname.split("/")[1] === "posts" ||
          location.pathname.split("/")[1] === "post" ||
          location.pathname.split("/")[1] === "forums" ||
          location.pathname.split("/")[1] === "forum"
        ) {
          console.log("permitted without token");
        } else {
          dispatch({ type: "USER_LOGOUT" });
          navigate("/");
        }
      }
    };
    getVerified();
  }, [verifyStatus, emailChange, modTab, tabSettingsDone, forumAction]);
};

//If user is not logged in, redirect to login page
export const CheckUserInfo = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const tkn = localStorage.getItem("tkn");

    if (!tkn || tkn === "undefined") {
      navigate("/");
    }
  }, []);
};

//Check if user is logged in properly
export const isUserLoggedIn = () => {
  const userInfo = localStorage.getItem("userInfo");
  const tkn = localStorage.getItem("tkn");

  if (!tkn || !userInfo || userInfo === "undefined" || tkn === "undefined") {
    return false;
  } else {
    return true;
  }
};

//Loading Page
export const LoadingPage = (setLoading) => {
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
};

//Hide Tab page on load
export const HideTabPage = () => {
  const { dispatch } = useContext(Context);
  useEffect(() => {
    dispatch({ type: "SHOW_TAB_PAGE", payload: null });
    dispatch({ type: "SHOW_TAB_PAGE_ID", payload: null });
    dispatch({ type: "ON_PAGE", payload: null });
  }, []);
};

//Fetch data for topics
export const GetTopics = (offset, lDispatch, topicUrl) => {
  const { dispatch } = useContext(Context);
  const navigate = useNavigate();
  useEffect(() => {
    const tkn = localStorage.getItem("tkn");
    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };
    const getData = async () => {
      try {
        dispatch({ type: "START_LOADING" });
        const { data } = await axios.get(topicUrl, config);

        if (data?.status === false) {
          dispatch({ type: "USER_LOGOUT" });
          navigate("/");
        } else {
          lDispatch({
            type: "SET_TOPIC_DATA",
            payload: { topics: data?.res, offset: data?.offset },
          });
          dispatch({ type: "STOP_LOADING" });
          if (data.res.length === 0) {
            dispatch({ type: "NO_MORE_TOPIC" });
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [offset]);
};

//Fetch data for forum
export const GetForumData = (offset, lDispatch, topicUrl) => {
  const { dispatch } = useContext(Context);
  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };
  const [status, setStatus] = useState("");
  const [msg, setMsg] = useState(null);

  const fetchDescData = async (tabId) => {
    try {
      const { data } = await axios.get(`/u/t?t=${tabId}`, config);

      lDispatch({ type: "SET_DESC_DATA", payload: data });

      if (data?.fllwng === true) {
        lDispatch({
          type: "SET_FOLLOW_STATUS",
          payload: "unfollow",
        });
      } else {
        lDispatch({ type: "SET_FOLLOW_STATUS", payload: "follow" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getData = async () => {
      try {
        dispatch({ type: "START_LOADING" });
        const { data } = await axios.get(topicUrl, config);

        if (data?.status) {
          setStatus("true");
        }

        if (data?.status === false) {
          setStatus("false");
          lDispatch({ type: "SET_ERROR_PAGE" });
          dispatch({ type: "STOP_LOADING" });
          setMsg(data?.msg);
        } else {
          lDispatch({
            type: "SET_TOPIC_DATA",
            payload: { topics: data?.res, offset: data?.offset },
          });
          lDispatch({
            type: "SET_FORUM_DETAILS",
            payload: data?.forum_details,
          });

          if (data.forum_details.tab_id || data.tab_details.tab_id) {
            fetchDescData(
              data?.forum_details?.tab_id || data.tab_details.tab_id
            );
          }
          dispatch({ type: "STOP_LOADING" });
          if (data.res.length === 0) {
            dispatch({ type: "NO_MORE_TOPIC" });
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [offset]);
  return { status, msg };
};

//Fetch data for Tab page topics
export const FetchTabTopics = (load, lDispatch, topicId, tabLId) => {
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useContext(Context);
  const { verifyStatus, currentTabId } = state;
  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };
  useEffect(() => {
    const getData = async () => {
      try {
        lDispatch({ type: "START_LOADING" });
        const { data } = await axios.get(
          `/u/topic?t=${topicId}&tab=${tabLId || currentTabId}`,
          config
        );

        if (data?.status === false) {
          lDispatch({ type: "STOP_LOADING" });
          enqueueSnackbar(data?.msg, { variant: "error" });
          return;
        } else {
          lDispatch({ type: "SET_TOPIC_DATA", payload: data.resp });
          lDispatch({ type: "STOP_LOADING" });
        }
      } catch (err) {
        console.log(err);
        return;
      }
    };

    getData();
  }, [load, verifyStatus]);
};

//Fetch data for a topic
export const FetchTopic = (lDispatch) => {
  const { state, dispatch } = useContext(Context);
  const { currentTopicId, currentTopicTabId, lastVisitedForumToken } = state;
  const location = useLocation();
  const { topicId, tabId } = useParams();
  console.log(topicId, tabId);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const tkn = localStorage.getItem("tkn");
    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };

    const yieldUrl = () => {
      if (
        location.pathname.split("/")[1] === "topics" ||
        location.pathname.split("/")[1] === "topic" ||
        location.pathname.split("/")[1] === "posts" ||
        location.pathname.split("/")[1] === "post"
      ) {
        if (tkn && !lastVisitedForumToken) {
          return `/u/topic/${tkn}?t=${topicId}&tab=${tabId}`;
        }
        if (lastVisitedForumToken) {
          return `/u/topic/${lastVisitedForumToken}?t=${topicId}&tab=${tabId}`;
        }
        if (!tkn && !lastVisitedForumToken) {
          return `/u/topic?t=${topicId}&tab=${tabId}`;
        }
      } else {
        return `/u/topic?t=${currentTopicId}&tab=${currentTopicTabId}`;
      }
    };
    const getData = async () => {
      try {
        dispatch({ type: "ERROR_PAGE_ON", payload: false });
        lDispatch({ type: "START_LOADING" });
        const { data } = await axios.get(yieldUrl(), config);
        if (!data?.status) {
          dispatch({ type: "ERROR_PAGE_ON", payload: true });
          enqueueSnackbar(data?.msg, { variant: "error" });
        } else {
          lDispatch({ type: "SET_TOPIC_DATA", payload: data?.resp });
        }

        lDispatch({ type: "STOP_LOADING" });
      } catch (err) {
        console.log(err);
        return;
      }
    };

    getData();
  }, [currentTopicId]);
};

//Fetch data for comments
export const FetchComments = (
  lDispatch,
  comntId,
  loadMore,
  isCommentDeleted,
  tpDispatch
) => {
  const location = useLocation();
  useEffect(() => {
    const tkn = localStorage.getItem("tkn");

    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };
    const yieldUrl = () => {
      if (
        location.pathname.split("/")[1] === "forums" &&
        location.pathname.split("/")[2] !== undefined
      ) {
        return `/loadmore/${
          location.pathname.split("/")[2]
        }?id=${comntId}&cntr=${loadMore}`;
      } else {
        return `/loadmore?id=${comntId}&cntr=${loadMore}`;
      }
    };
    const getData = async () => {
      try {
        const { data } = await axios.get(yieldUrl(), config);
        if (data?.status === false) {
          return;
        } else {
          if (data?.temp_array?.length !== 0) {
            lDispatch({
              type: "SET_COMMENT_DATA",
              payload: data["temp_array"],
            });

            if (typeof tpDispatch === "function") {
              tpDispatch({ type: "TURN_OFF_MORE" });
            }
          }
          lDispatch({ type: "RETURN" });
          lDispatch({ type: "REMAIN", payload: data["remaining"] });
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [loadMore, isCommentDeleted]);
};

//Get user subscription history
export const GetSubscription = (lDispatch, load) => {
  useEffect(() => {
    const getData = async () => {
      const tkn = localStorage.getItem("tkn");
      const config = {
        headers: {
          authorization: `${tkn}`,
        },
      };
      try {
        lDispatch({ type: "START_LOADING" });
        const { data } = await axios.get(`/vsubs?cntr=${load}`, config);
        lDispatch({ type: "STOP_LOADING" });
        if (data.all_subs.length === 0) {
          lDispatch({ type: "NO_MORE_SUB" });
        }
        lDispatch({
          type: "SET_SUB_DATA",
          payload: {
            allSubs: data.all_subs,
            offset: data.offset,
          },
        });
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [load]);
};

//Check if outside is clicked

export const useClickOut = ({
  onState,
  mainRef,
  subRef,
  dispatch,
  dispatchType,
  setFunc,
}) => {
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        onState &&
        mainRef.current &&
        !mainRef.current.contains(e.target) &&
        !subRef.current.contains(e.target)
      ) {
        dispatch ? dispatch({ type: dispatchType }) : setFunc(!onState);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [onState, mainRef, dispatchType, subRef, dispatch, setFunc]);
};

//handle push notification

export const usePushNotification = (ctgr, chnl) => {
  const tkn = localStorage.getItem("tkn");
  const { enqueueSnackbar } = useSnackbar();
  const url_str = `${notifyBaseUrl}/subscr/${ctgr}/${chnl}?tkn=${tkn}`;
  useEffect(() => {
    if (ctgr === "vrfd" && chnl) {
      const notiSubs = JSON.parse(localStorage.getItem("notiSubs"));
      const alreadyConnected = notiSubs?.includes(chnl);
      if (alreadyConnected) {
        return;
      } else {
        const sx = new EventSource(url_str);
        sx.onmessage = (event) => {
          enqueueSnackbar(event?.data?.split("|")[1], { variant: "success" });
          if (notiSubs) {
            const updateSubs = [...notiSubs, chnl];
            localStorage.setItem("notiSubs", JSON.stringify(updateSubs));
            console.log("updated", updateSubs);
          } else {
            localStorage.setItem("notiSubs", JSON.stringify([chnl]));
          }
        };
        sx.onerror = (error) => {
          console.log("sx error:", error);
        };
      }
    }
  }, [ctgr, chnl]);
};

//Get notifications
export const GetNotifications = () => {
  const { dispatch } = useContext(Context);
  useEffect(() => {
    const getData = async () => {
      const tkn = localStorage.getItem("tkn");
      const config = {
        headers: {
          authorization: `${tkn}`,
        },
      };
      try {
        dispatch({ type: "START_NOTIFICATIONS_LOADING" });
        const gResponse = await axios.get(`/ntfx/count`, config);

        if (gResponse?.data?.status) {
          dispatch({
            type: "SET_NOTIFICATION_COUNT",
            payload: gResponse?.data?.total,
          });
          if (gResponse?.data?.total === 0) {
            try {
              const sResponse = await axios.get(`/ntfx/count/4`, config);
              if (sResponse?.data?.status) {
                dispatch({
                  type: "SET_NOTIFICATIONS",
                  payload: sResponse?.data,
                });
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            dispatch({
              type: "SET_NOTIFICATIONS",
              payload: gResponse?.data,
            });
          }
        }
        dispatch({ type: "STOP_NOTIFICATIONS_LOADING" });
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);
};

//Get likes and comments updates on post page

export const GetCommentsUpdate = (postId, localDispatch) => {
  useEffect(() => {
    const fetchData = async () => {
      const tkn = localStorage.getItem("tkn");
      const config = {
        headers: {
          authorization: `${tkn}`,
        },
      };
      try {
        const { data } = await axios.get(
          `/ntfx/count/comments?posts=${postId}`,
          config
        );

        if (data?.status) {
          localDispatch({
            type: "UPDATE_COMMENT",
            payload: {
              pId: parseInt(data?.msg[0]?.post_id),
              newCount: data?.msg[0]?.comments,
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    // Fetch data immediately, then every 10 seconds
    fetchData();
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, [postId]); // Empty dependency array ensures this runs once on mount
};

//Scroll to section

export const useScrollToSection = (targetRef, vert) => {
  useEffect(() => {
    targetRef?.current?.scrollIntoView({ behavior: "smooth", block: vert });
  }, []);
};
