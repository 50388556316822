import { useContext, useEffect, useRef } from "react";

import qs from "qs";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Dialog,
  ThemeProvider,
  Tooltip,
} from "@mui/material";

import "./Topics.scss";
import TabPage from "../TabPage/TabPage";
import TopicComment from "../TopicComment/TopicComment";
import EditPostTopic from "../EditPostTopic/EditPostTopic";
import ImageSlider from "../ImageSlider/ImageSlider";
import axios from "../../utils/Axios";
import { UidGenerator } from "../../utils/UidGenerator";
import { Context } from "../../utils/Context";
import {
  ActionMenuIcon,
  FlagIcon,
  LikeIcon,
  SaveIcon,
  SettingsIcon,
} from "../Icons/Icons";
import { homeDialogTheme } from "../../utils/MuiThemes";
import { TopicTooltipTheme } from "../../utils/MuiThemes";
import { Circle } from "@mui/icons-material";
import { HideTabPage, isUserLoggedIn, useClickOut } from "../../utils/Effects";
import { FormatTime, canEditText } from "../../utils/TimeFormatter";
import { RenderContent } from "../../utils/RenderContent";
import TopicPage from "../TopicPage/TopicPage";
import { useLocation, useNavigate } from "react-router-dom";

function Topics({ title, localState, localDispatch, free }) {
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register, reset } = useForm();
  const { t } = useTranslation(["components, extras"]);
  const { dispatch, state } = useContext(Context);
  const actionContainerTopicRef = useRef();
  const actionTopicMenuRef = useRef();
  const navigate = useNavigate();

  const {
    userInfo,
    apiLoading,
    settingLoading,
    loadText,
    showSetting,
    topicId,
    currentTabName,
    currentTabId,
    currentTopicId,
    currentTopicTabId,
    timeLine,
    verified,
    showAction,
    showMore,
  } = state;

  const {
    showComment,
    parent,
    topicData,
    posting,
    showModal,
    load,
    open,
    newComment,
  } = localState;

  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  const location = useLocation();

  HideTabPage();

  useClickOut({
    onState: showAction,
    mainRef: actionTopicMenuRef,
    subRef: actionContainerTopicRef,
    dispatch: dispatch,
    dispatchType: "HIDE_ACTION",
  });

  useEffect(() => {
    const options = document.querySelectorAll(".option");
    options.forEach((option) => {
      if (timeLine === option.value) {
        option.setAttribute("selected", "true");
      }
    });
  }, [timeLine]);

  //Open modal
  const handleClickOpen = (id) => {
    localDispatch({ type: "SHOW_MODAL", payload: id });
    localDispatch({ type: "OPEN_MODAL" });
  };

  //Close modal
  const handleClose = () => {
    localDispatch({ type: "CLOSE_MODAL" });
  };

  //Delete topic
  const handleDeleteTopic = async () => {
    const { data } = await axios.post(
      "/delc",
      {
        id: `${showModal}`,
        type: "c",
      },
      config
    );
    if (data?.status === true) {
      localDispatch({ type: "DELETE_TOPIC", payload: showModal });
      enqueueSnackbar(data.msg, { variant: "success" });
      handleClose();
    } else {
      enqueueSnackbar(data.msg, { variant: "error" });
      handleClose();
    }
  };
  //Open and close comment box
  const handleShowComment = (id, parent) => {
    if (showComment !== id) {
      localDispatch({ type: "SHOW_COMMENT", payload: id });
      localDispatch({ type: "PARENT", payload: parent });
    } else {
      localDispatch({ type: "SHOW_COMMENT", payload: null });
      localDispatch({ type: "PARENT", payload: null });
    }
  };

  //see more/less texts from a topic
  const handleShowMore = (id) => {
    if (showMore?.includes(id)) {
      dispatch({ type: "SHOW_LESS", payload: id });
    } else {
      dispatch({ type: "SHOW_MORE", payload: id });
    }
  };

  //Post comment
  const postComment = async (newCmntTxt) => {
    const userStatus = isUserLoggedIn();
    if (!userStatus && location.pathname.split("/")[1] === "forums") {
      dispatch({ type: "OPEN_AUTH_MODAL" });
      return;
    }
    const inputData = {
      text: newCmntTxt,
      tab: parent,
      post_id: showComment,
    };
    const url =
      location.pathname.split("/")[1] === "forums" && !free
        ? `/newc/${location.pathname.split("/")[2]}`
        : "/newc";
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        authorization: `${tkn}`,
      },
      data: qs.stringify(inputData),
      url,
    };
    if (newCmntTxt === "") {
      enqueueSnackbar("Empty comment not allowed!", { variant: "error" });
      return;
    }
    localDispatch({ type: "POSTING" });
    const { data } = await axios(options);
    localDispatch({ type: "POSTING_DONE" });
    if (data?.status) {
      reset();
      localDispatch({ type: "SHOW_COMMENT", payload: null });
      localDispatch({ type: "PARENT", payload: null });
      localDispatch({
        type: "UPDATE_COMMENT",
        payload: {
          pId: parseInt(data?.new_c?.parent),
          newCount: data?.new_count,
        },
      });
      localDispatch({ type: "NEW_COMMENT", payload: data });
    }
  };

  //Load more topics
  const loadMore = () => {
    localDispatch({ type: "SET_LOAD" });
  };

  //Show Tab Page
  const showTabPage = (topicData) => {
    dispatch({ type: "SHOW_TAB_PAGE", payload: topicData?.parent });
    dispatch({ type: "SHOW_TAB_PAGE_ID", payload: topicData?.tab_id });
    dispatch({ type: "ON_PAGE", payload: "tab" });
  };

  const handleNavigateTopicPage = (comntId, tabId) => {
    dispatch({
      type: "SET_PREVIOUS_PAGE",
      payload: location.pathname.split("/")[1],
    });

    if (location.pathname.split("/")[1] === "forums") {
      if (location.pathname.split("/")[2] !== undefined) {
        dispatch({
          type: "SET_LAST_VISITED_FORUM_TOKEN",
          payload: location.pathname.split("/")[2],
        });
      }
      navigate(`/topics/${comntId}/${tabId}`);
    } else {
      dispatch({
        type: "SHOW_TOPIC_PAGE",
        payload: { comntId, tabId },
      });
    }

    dispatch({ type: "ON_PAGE", payload: "topic" });
  };

  const handleNavigateTabPage = (topicData) => {
    dispatch({
      type: "SET_PREVIOUS_PAGE",
      payload: location.pathname.split("/")[1],
    });
    location.pathname.split("/")[1] === "forums"
      ? navigate(`/tabs/${topicData?.tab_id}`)
      : showTabPage(topicData);
  };

  const TopicHead = () => {
    if (title === "anon") {
      return (
        <div
          className="tabsHead"
          style={{
            zIndex: (currentTabName !== null || currentTopicId !== null) && 0,
          }}
        >
          <select
            className="headSelect"
            defaultValue={"All Topics"}
            onChange={(e) => {
              dispatch({ type: "SET_TIME_LINE", payload: e.target.value });
            }}
          >
            <option value={"All Topics"}>{t("components:AllTs")}</option>
            <option value={"Public Topics"}>{t("components:publicTs")}</option>
            <option value={"Private Topics"}>
              {t("components:privateTs")}
            </option>
          </select>
          <div className="headDesc">{t("components:AHDesc")}</div>
        </div>
      );
    } else if (title === "pub") {
      return (
        <div
          className="tabsHead"
          style={{
            zIndex: (currentTabName !== null || currentTopicId !== null) && 0,
          }}
        >
          <select
            className="headSelect"
            defaultValue={"Public Topics"}
            onChange={(e) => {
              dispatch({ type: "SET_TIME_LINE", payload: e.target.value });
            }}
          >
            <option value={"All Topics"}>{t("components:AllTs")}</option>
            <option value={"Public Topics"}>{t("components:publicTs")}</option>
            <option value={"Private Topics"}>
              {t("components:privateTs")}
            </option>
          </select>
          <div className="headDesc">{t("components:publicHDesc")}</div>
        </div>
      );
    } else if (title === "pri") {
      return (
        <div
          className="tabsHead"
          style={{
            zIndex: (currentTabName !== null || currentTopicId !== null) && 0,
          }}
        >
          <select
            className="headSelect"
            defaultValue={"Private Topics"}
            onChange={(e) => {
              dispatch({ type: "SET_TIME_LINE", payload: e.target.value });
            }}
          >
            <option value={"All Topics"}>{t("components:AllTs")}</option>
            <option value={"Public Topics"}>{t("components:publicTs")}</option>
            <option value={"Private Topics"}>
              {t("components:privateTs")}
            </option>
          </select>
          <div className="headDesc">{t("components:privateHDesc")}</div>
        </div>
      );
    } else if (title === "per") {
      return (
        <div
          className="tabsHead"
          style={{
            zIndex: (currentTabName !== null || currentTopicId !== null) && 0,
          }}
        >
          <div className="head">{t("components:personalTs")}</div>
        </div>
      );
    } else if (title === "fg") {
      return (
        <div
          className="tabsHead"
          style={{
            zIndex: (currentTabName !== null || currentTopicId !== null) && 0,
          }}
        >
          <div className="head">{t("components:fTopics")}</div>
          <div className="headDesc">{t("components:fHDesc")}</div>
        </div>
      );
    } else if (title === "lk") {
      return (
        <div
          className="tabsHead"
          style={{
            zIndex: (currentTabName !== null || currentTopicId !== null) && 0,
          }}
        >
          <div className="head">{t("components:lTopics")}</div>
        </div>
      );
    } else if (title === "sv") {
      return (
        <div
          className="tabsHead"
          style={{
            zIndex: (currentTabName !== null || currentTopicId !== null) && 0,
          }}
        >
          <div className="head">{t("components:sTopics")}</div>
        </div>
      );
    }
  };

  const FresherMessage = () => {
    if (title === "anon") {
      return (
        <div className="fresherMessage">
          <p>{t("components:noToDisplay")}</p>
          <p>{t("components:noAnonymous")}</p>
          <p>{t("components:followHelp")}</p>
          <div className="noContent">
            <img src="/assets/images/noContent.svg" alt="" />
          </div>
        </div>
      );
    } else if (title === "pri") {
      return (
        <div className="fresherMessage">
          <p>{t("components:noToDisplay")}</p>
          <p>{t("components:noPrivate")}</p>
          <p>{t("components:listAnonymous")}</p>
          <div className="noContent">
            <img src="/assets/images/noContent.svg" alt="" />
          </div>
        </div>
      );
    } else {
      return (
        <div className="fresherMessage">
          <div className="noContent">
            <img src="/assets/images/noContent.svg" alt="" />
          </div>
        </div>
      );
    }
  };
  return (
    <>
      {(currentTabName !== null || currentTabId !== null) && <TabPage />}
      {currentTopicId !== null && currentTopicTabId !== null && <TopicPage />}
      {topicData?.length === 0 &&
      load === 0 &&
      !apiLoading &&
      location.pathname.split("/")[1] !== "forums" ? (
        <div className="tabsContainer">
          <TopicHead />
          <FresherMessage />
        </div>
      ) : (
        <div className="tabsContainer">
          {location.pathname.split("/")[1] !== "forums" ? (
            <TopicHead />
          ) : (
            <div className="forumPostSpace"></div>
          )}
          <div className="tpSuperContainer">
            {topicData?.map((topicData) => {
              return (
                <div
                  className="tpContainer"
                  style={{
                    boxShadow:
                      location.pathname.split("/")[1] === "forums"
                        ? "0px 0px 1.8px 0.2px #c4c4c4"
                        : "0px 1px 1px #c4c4c4",
                  }}
                  key={topicData?.comnt_id}
                >
                  <div className="subContainer">
                    <div
                      className="firstBlock"
                      onClick={() => handleNavigateTabPage(topicData)}
                    >
                      <button className="topicTitle">
                        {topicData?.tab_title}
                      </button>
                      <small className="topicParent">
                        @{topicData?.parent}
                      </small>
                    </div>
                    <div className="secondBlock">
                      <div className="topicDescription">
                        <span>
                          <span
                            onClick={() =>
                              handleNavigateTopicPage(
                                topicData?.comnt_id,
                                parseInt(topicData?.tab_id)
                              )
                            }
                          >
                            {showMore.includes(topicData.comnt_id)
                              ? RenderContent(
                                  topicData?.content?.split(/\r?\n/),
                                  <span className="identifier">
                                    <ThemeProvider theme={TopicTooltipTheme}>
                                      <Tooltip
                                        title={t(
                                          "components:identifierTooltip"
                                        )}
                                      >
                                        <span>
                                          -
                                          {UidGenerator(
                                            topicData?.time,
                                            topicData?.usr_id
                                          )}
                                        </span>
                                      </Tooltip>
                                    </ThemeProvider>
                                  </span>,
                                  "topic"
                                )
                              : RenderContent(
                                  topicData?.content
                                    ?.substring(0, 200)
                                    .split(/\r?\n/),
                                  <span className="identifier">
                                    <ThemeProvider theme={TopicTooltipTheme}>
                                      <Tooltip
                                        title={t(
                                          "components:identifierTooltip"
                                        )}
                                      >
                                        <span>
                                          -
                                          {UidGenerator(
                                            topicData?.time,
                                            topicData?.usr_id
                                          )}
                                        </span>
                                      </Tooltip>
                                    </ThemeProvider>
                                  </span>,
                                  "topic"
                                )}
                          </span>
                          {topicData?.content?.length > 200 && (
                            <button
                              onClick={() => {
                                handleShowMore(topicData?.comnt_id);
                              }}
                            >
                              {showMore.includes(topicData?.comnt_id) ? (
                                <span style={{ marginLeft: "2px" }}>
                                  {t("extras:btnSL")}
                                </span>
                              ) : (
                                t("extras:btnSM")
                              )}
                            </button>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="thirdBlock">
                      <div className="status">
                        <div>
                          {topicData?.settings[0] === true
                            ? t("components:publicT")
                            : t("components:privateT")}
                          <Circle className="dot" />
                        </div>
                        <div>
                          {topicData?.edited === true && (
                            <>
                              {t("components:edited")}{" "}
                              <Circle className="dot" />
                            </>
                          )}
                        </div>
                      </div>
                      <div>{FormatTime(topicData?.time)}</div>
                    </div>

                    <div className="fourthBlock">
                      <div className="fourthBlockLeft">
                        <LikeIcon
                          postId={topicData?.comnt_id}
                          like={topicData?.likes}
                          lkSt={topicData?.like_st}
                        />
                        <span className="badgeIcon">
                          <SaveIcon
                            postId={topicData?.comnt_id}
                            saved={topicData?.save_st}
                          />
                        </span>
                        {(verified?.some(
                          (tab) => tab?.name === topicData?.parent
                        ) ||
                          location.pathname.split("/")[1] === "forums") && (
                          <>
                            <div
                              className="badgeCont"
                              onClick={() =>
                                handleShowComment(
                                  topicData?.comnt_id,
                                  topicData?.parent
                                )
                              }
                            >
                              <img
                                src="/assets/images/comment.svg"
                                alt=""
                                className="badgeIicon"
                              />{" "}
                              {topicData?.children > 0 && (
                                <span className="badgeContText">
                                  {topicData?.children === 0
                                    ? ""
                                    : topicData?.children}
                                </span>
                              )}
                            </div>

                            <span className="badgeIcon">
                              <FlagIcon
                                postId={topicData?.comnt_id}
                                flagged={topicData?.settings[1]}
                              />
                            </span>
                          </>
                        )}
                      </div>

                      <div className="fourthBlockRight">
                        <ActionMenuIcon
                          userId={topicData?.usr_id}
                          parent={topicData?.parent}
                        >
                          <div
                            className="actionMenu"
                            onClick={() =>
                              dispatch({
                                type: "SHOW_ACTION",
                                payload: topicData?.comnt_id,
                              })
                            }
                            ref={actionTopicMenuRef}
                          >
                            <img src="/assets/images/action.png" alt="" />
                          </div>
                        </ActionMenuIcon>

                        <div
                          className={
                            showAction === topicData?.comnt_id &&
                            currentTabName === null &&
                            currentTopicId === null
                              ? "actionContainer"
                              : "actionContainer hideAction"
                          }
                          ref={actionContainerTopicRef}
                        >
                          {topicData?.usr_id === userInfo?.idd && (
                            <img
                              src="/assets/images/recycle.svg"
                              alt=""
                              className="icon"
                              onClick={() =>
                                handleClickOpen(topicData?.comnt_id)
                              }
                            />
                          )}
                          <ThemeProvider theme={homeDialogTheme}>
                            <Dialog
                              open={open}
                              onClose={handleClose}
                              maxWidth="xs"
                              PaperProps={{
                                style: {
                                  boxShadow: "none",
                                },
                              }}
                            >
                              <div className="tpDialogContainer">
                                <p>{t("components:sureDeleteTopic")}</p>
                                <p> {t("components:noUndo")}</p>
                                <div className="btn">
                                  <button
                                    onClick={handleClose}
                                    className="btnCancel"
                                  >
                                    {t("extras:btnCancel")}
                                  </button>
                                  <button
                                    onClick={() =>
                                      handleDeleteTopic(topicData?.comnt_id)
                                    }
                                    className="btnExecute"
                                  >
                                    {t("extras:btnDelete")}
                                  </button>
                                </div>
                              </div>
                            </Dialog>
                          </ThemeProvider>
                          <SettingsIcon
                            cmntId={topicData?.comnt_id}
                            usrId={topicData?.usr_id}
                            parent={topicData?.parent}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {showComment === topicData?.comnt_id && (
                    <form
                      onSubmit={handleSubmit(({ newCmntTxt }) =>
                        postComment(newCmntTxt)
                      )}
                    >
                      <div className="postComment">
                        <textarea
                          {...register("newCmntTxt")}
                          cols="30"
                          rows="4"
                          placeholder={t("components:pHCommentBox")}
                        />

                        <div className="btn">
                          <button>
                            {posting ? (
                              <>
                                {t("extras:btnPosting")}
                                <CircularProgress
                                  size={15}
                                  className="btnProgress"
                                />
                              </>
                            ) : (
                              t("extras:btnPost")
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                  {topicData?.attchmnts !== (null || undefined) &&
                    topicData?.attchmnts?.length >= 1 && (
                      <ImageSlider slides={topicData?.attchmnts} />
                    )}
                  {settingLoading && topicId === topicData?.comnt_id && (
                    <div className="loadmore">
                      <CircularProgress color="inherit" size={25} />
                    </div>
                  )}
                  {showSetting === topicData?.comnt_id && (
                    <EditPostTopic
                      title={
                        userInfo?.idd === topicData?.usr_id &&
                        canEditText(topicData?.time)
                          ? t("components:editTopic")
                          : t("components:editVisibility")
                      }
                      func={t("extras:btnSaveEdit")}
                      noAtt
                      editInx={topicData?.comnt_id}
                      topicTable={topicData?.parent}
                      usrId={topicData?.usr_id}
                      defaultText={topicData?.content}
                      time={topicData?.time}
                    />
                  )}
                  {topicData?.children !== 0 && (
                    <TopicComment
                      comntId={topicData?.comnt_id}
                      pChild={topicData?.children}
                      newSibling={newComment}
                    />
                  )}
                </div>
              );
            })}
          </div>
          {!localState?.errorPage && (
            <div className="loadmore loadCircular">
              {apiLoading && <CircularProgress color="inherit" size={25} />}
              <button onClick={loadMore}>
                {loadText === "Load more topics"
                  ? t("components:loadMoreTopics")
                  : loadText === "Loading"
                  ? t("components:loading")
                  : t("components:noMoreTopics")}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Topics;
