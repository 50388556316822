import { useContext, useEffect, useReducer, useRef } from "react";

import qs from "qs";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Dialog,
  IconButton,
  ThemeProvider,
  Tooltip,
} from "@mui/material";

import "./TopicPage.scss";
import TopicComment from "../TopicComment/TopicComment";
import EditPostTopic from "../EditPostTopic/EditPostTopic";
import ImageSlider from "../ImageSlider/ImageSlider";
import axios from "../../utils/Axios";
import { UidGenerator } from "../../utils/UidGenerator";
import { Context } from "../../utils/Context";
import {
  ActionMenuIcon,
  FlagIcon,
  LikeIcon,
  SaveIcon,
  SettingsIcon,
} from "../Icons/Icons";
import { commentDialogTheme } from "../../utils/MuiThemes";
import { TopicTooltipTheme } from "../../utils/MuiThemes";
import { Circle } from "@mui/icons-material";
import {
  FetchTopic,
  GetCommentsUpdate,
  useClickOut,
} from "../../utils/Effects";
import { canEditText } from "../../utils/TimeFormatter";
import { RenderContent } from "../../utils/RenderContent";
import { initialState, reducer } from "./state";
import { AnonContext } from "../AnonymousTabs/State";
import { PubContext } from "../PublicTabs/State";
import { PriContext } from "../PrivateTabs/State";
import { PerContext } from "../PersonalTopics/State";
import { FgContext } from "../Flagged/State";
import { LkContext } from "../Liked/State";
import { SvContext } from "../Saved/State";
import Moment from "react-moment";

function TopicPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register } = useForm();
  const { t } = useTranslation(["components, extras"]);
  const { dispatch, state } = useContext(Context);
  const actionMenuRef = useRef();
  const actionContainerRef = useRef();

  const { anonDispatch } = useContext(AnonContext);
  const { pubDispatch } = useContext(PubContext);
  const { priDispatch } = useContext(PriContext);
  const { perDispatch } = useContext(PerContext);
  const { fgDispatch } = useContext(FgContext);
  const { lkDispatch } = useContext(LkContext);
  const { svDispatch } = useContext(SvContext);

  const [localState, localDispatch] = useReducer(reducer, initialState);

  const {
    userInfo,
    settingLoading,
    showSetting,
    topicId,
    whichPageShouldOn,
    timeLine,
    verified,
    showAction,
    showMore,
  } = state;

  const {
    showComment,
    parent,
    topicData,
    posting,
    showModal,
    open,
    loading,
    newComment,
    moreComment,
  } = localState;

  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };
  FetchTopic(localDispatch);
  useClickOut({
    onState: showAction,
    mainRef: actionMenuRef,
    subRef: actionContainerRef,
    dispatch: dispatch,
    dispatchType: "HIDE_ACTION",
  });

  useEffect(() => {
    const options = document.querySelectorAll(".option");
    options.forEach((option) => {
      if (timeLine === option.value) {
        option.setAttribute("selected", "true");
      }
    });
  }, [timeLine]);

  //Open modal
  const handleClickOpen = (id) => {
    localDispatch({ type: "SHOW_MODAL", payload: id });
    localDispatch({ type: "OPEN_MODAL" });
  };

  //Close modal
  const handleClose = () => {
    localDispatch({ type: "CLOSE_MODAL" });
  };

  //Delete topic
  const handleDeleteTopic = async (id) => {
    const { data } = await axios.post(
      "/delc",
      {
        id: showModal,
        type: "c",
      },
      config
    );
    if (data?.status === true) {
      localDispatch({ type: "DELETE_TOPIC", payload: showModal });
      if (typeof anonDispatch === "function") {
        anonDispatch({ type: "DELETE_TOPIC", payload: showModal });
      }
      if (typeof pubDispatch === "function") {
        pubDispatch({ type: "DELETE_TOPIC", payload: showModal });
      }
      if (typeof priDispatch === "function") {
        priDispatch({ type: "DELETE_TOPIC", payload: showModal });
      }
      if (typeof perDispatch === "function") {
        perDispatch({ type: "DELETE_TOPIC", payload: showModal });
      }
      if (typeof fgDispatch === "function") {
        fgDispatch({ type: "DELETE_TOPIC", payload: showModal });
      }
      if (typeof lkDispatch === "function") {
        lkDispatch({ type: "DELETE_TOPIC", payload: showModal });
      }
      if (typeof svDispatch === "function") {
        svDispatch({ type: "DELETE_TOPIC", payload: showModal });
      }
      dispatch({ type: "TOPIC_DELETE_TOGGLE" });
      enqueueSnackbar(data.msg, { variant: "success" });
      handleClose();
    } else {
      enqueueSnackbar(data.msg, { variant: "error" });
      handleClose();
    }
  };
  //Open and close comment box
  const handleShowComment = (id, parent) => {
    if (showComment !== id) {
      localDispatch({ type: "SHOW_COMMENT", payload: id });
      localDispatch({ type: "PARENT", payload: parent });
    } else {
      localDispatch({ type: "SHOW_COMMENT", payload: null });
      localDispatch({ type: "PARENT", payload: null });
    }
  };

  //see more/less texts from a topic
  const handleShowMore = (id) => {
    if (showMore?.includes(id)) {
      dispatch({ type: "SHOW_LESS", payload: id });
    } else {
      dispatch({ type: "SHOW_MORE", payload: id });
    }
  };

  //Post comment
  const postComment = async ({ newCmntTxt }) => {
    const inputData = {
      text: newCmntTxt,
      tab: parent,
      post_id: showComment,
    };
    const url = "/newc";

    const options = {
      method: "POST",
      headers: {
        authorization: tkn,
        "content-type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(inputData),
      url,
    };
    localDispatch({ type: "POSTING" });
    const { data } = await axios(options);
    localDispatch({ type: "POSTING_DONE" });
    localDispatch({ type: "SHOW_COMMENT", payload: null });
    localDispatch({ type: "PARENT", payload: null });

    localDispatch({ type: "NEW_COMMENT", payload: data });
    localDispatch({
      type: "UPDATE_COMMENT",
      payload: {
        pId: parseInt(data?.new_c?.parent),
        newCount: data?.new_count,
      },
    });
    if (typeof anonDispatch === "function") {
      anonDispatch({
        type: "UPDATE_COMMENT",
        payload: {
          pId: parseInt(data?.new_c?.parent),
          newCount: data?.new_count,
        },
      });
      anonDispatch({ type: "NEW_COMMENT", payload: data });
    }
    if (typeof pubDispatch === "function") {
      pubDispatch({
        type: "UPDATE_COMMENT",
        payload: {
          pId: parseInt(data?.new_c?.parent),
          newCount: data?.new_count,
        },
      });
      pubDispatch({ type: "NEW_COMMENT", payload: data });
    }
    if (typeof priDispatch === "function") {
      priDispatch({
        type: "UPDATE_COMMENT",
        payload: {
          pId: parseInt(data?.new_c?.parent),
          newCount: data?.new_count,
        },
      });
      priDispatch({ type: "NEW_COMMENT", payload: data });
    }
    if (typeof perDispatch === "function") {
      perDispatch({
        type: "UPDATE_COMMENT",
        payload: {
          pId: parseInt(data?.new_c?.parent),
          newCount: data?.new_count,
        },
      });
      perDispatch({ type: "NEW_COMMENT", payload: data });
    }
    if (typeof fgDispatch === "function") {
      fgDispatch({
        type: "UPDATE_COMMENT",
        payload: {
          pId: parseInt(data?.new_c?.parent),
          newCount: data?.new_count,
        },
      });
      fgDispatch({ type: "NEW_COMMENT", payload: data });
    }
    if (typeof lkDispatch === "function") {
      lkDispatch({
        type: "UPDATE_COMMENT",
        payload: {
          pId: parseInt(data?.new_c?.parent),
          newCount: data?.new_count,
        },
      });
      lkDispatch({ type: "NEW_COMMENT", payload: data });
    }
    if (typeof svDispatch === "function") {
      svDispatch({
        type: "UPDATE_COMMENT",
        payload: {
          pId: parseInt(data?.new_c?.parent),
          newCount: data?.new_count,
        },
      });
      svDispatch({ type: "NEW_COMMENT", payload: data });
    }
  };
  //Show Tab Page
  const showTabPage = (topicData) => {
    dispatch({ type: "SHOW_TAB_PAGE", payload: topicData?.parent });
    dispatch({ type: "SHOW_TAB_PAGE_ID", payload: topicData?.tab_id });
    dispatch({ type: "ON_PAGE", payload: "tab" });
  };

  GetCommentsUpdate(JSON.stringify(topicData[0]?.comnt_id), localDispatch);

  return (
    <div
      className="tabContainer"
      style={{ display: whichPageShouldOn === "tab" && "none" }}
    >
      <div className="topicPageNavCont">
        <nav className="topicPageNav">
          <div className="topicIconCont">
            <IconButton
              className="topicBtn"
              onClick={() => {
                dispatch({
                  type: "SHOW_TOPIC_PAGE",
                  payload: { comntId: null, tabId: null },
                });
                dispatch({ type: "ON_PAGE", payload: "tab" });
              }}
            >
              <img
                src="/assets/images/arrowBackBlue.png"
                alt=""
                className="tpNavArrowBack"
              />
            </IconButton>
          </div>
          <div className="topicTitleCont">
            {" "}
            <h3 className="topicTitle">{t("extras:btnPost")}</h3>
          </div>
        </nav>
      </div>
      {loading ? (
        <div className="loadmore">
          <CircularProgress color="inherit" size={25} />
        </div>
      ) : (
        topicData?.map((topicData) => {
          return (
            <div className="tptContainer" key={topicData?.comnt_id}>
              <div className="subContainer">
                <div
                  className="firstBlockTopicPage"
                  onClick={() => showTabPage(topicData)}
                >
                  <button className="topicTitle">{topicData?.tab_title}</button>
                  <small className="topicParentTopicPage">
                    @{topicData?.parent}
                  </small>
                </div>
                <div className="secondBlock">
                  <div className="topicDescription">
                    <span>
                      {showMore?.includes(topicData.comnt_id)
                        ? RenderContent(
                            topicData?.content?.split(/\r?\n/),
                            <span className="identifier">
                              <ThemeProvider theme={TopicTooltipTheme}>
                                <Tooltip
                                  title={t("components:identifierTooltip")}
                                >
                                  <span>
                                    -
                                    {UidGenerator(
                                      topicData?.time,
                                      topicData.usr_id
                                    )}
                                  </span>
                                </Tooltip>
                              </ThemeProvider>
                            </span>,
                            "topic"
                          )
                        : RenderContent(
                            topicData?.content
                              ?.substring(0, 200)
                              .split(/\r?\n/),
                            <span className="identifier">
                              <ThemeProvider theme={TopicTooltipTheme}>
                                <Tooltip
                                  title={t("components:identifierTooltip")}
                                >
                                  <span>
                                    -
                                    {UidGenerator(
                                      topicData?.time,
                                      topicData.usr_id
                                    )}
                                  </span>
                                </Tooltip>
                              </ThemeProvider>
                            </span>,
                            "topic"
                          )}
                      {topicData?.content?.length > 200 && (
                        <button
                          onClick={() => {
                            handleShowMore(topicData.comnt_id);
                          }}
                        >
                          {showMore?.includes(topicData.comnt_id) ? (
                            <span style={{ marginLeft: "2px" }}>
                              {t("extras:btnSL")}
                            </span>
                          ) : (
                            t("extras:btnSM")
                          )}
                        </button>
                      )}
                    </span>
                  </div>
                </div>
                <div className="thirdBlock">
                  <div className="status">
                    <div>
                      {topicData?.settings[0] === true
                        ? t("components:publicT")
                        : t("components:privateT")}
                      <Circle className="dot" />
                    </div>
                    <div>
                      {topicData?.edited === true && (
                        <>
                          {t("components:edited")} <Circle className="dot" />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="small">
                    <Moment format="DD/MM/YYYY">{topicData?.time}</Moment>
                  </div>{" "}
                  <Circle className="dot" />
                  <div className="small">
                    <Moment format="HH:mm">{topicData?.time}</Moment>
                  </div>
                </div>

                <div className="fourthBlock">
                  <div className="fourthBlockLeft">
                    <LikeIcon
                      postId={topicData?.comnt_id}
                      like={topicData?.likes}
                      lkSt={topicData?.like_st}
                    />
                    <span className="badgeIcon">
                      <SaveIcon
                        postId={topicData?.comnt_id}
                        saved={topicData?.save_st}
                      />
                    </span>
                    {verified?.some(
                      (tab) => tab?.name === topicData?.parent?.toString()
                    ) && (
                      <>
                        <div
                          className="badgeCont"
                          onClick={() =>
                            handleShowComment(
                              topicData?.comnt_id,
                              topicData?.parent
                            )
                          }
                        >
                          <img
                            src="/assets/images/comment.svg"
                            alt=""
                            className="badgeIicon"
                          />{" "}
                          {topicData?.children > 0 && (
                            <span className="badgeContText">
                              {topicData?.children === 0
                                ? ""
                                : topicData?.children}
                            </span>
                          )}
                        </div>

                        <span className="badgeIcon">
                          <FlagIcon
                            postId={topicData?.comnt_id}
                            flagged={topicData?.settings[1]}
                          />
                        </span>
                      </>
                    )}
                  </div>

                  <div className="fourthBlockRight">
                    <ActionMenuIcon
                      userId={topicData?.usr_id}
                      parent={topicData?.parent}
                    >
                      <div
                        className="actionMenu"
                        onClick={() =>
                          dispatch({
                            type: "SHOW_ACTION",
                            payload: topicData?.comnt_id,
                          })
                        }
                        ref={actionMenuRef}
                      >
                        <img src="/assets/images/action.png" alt="" />
                      </div>
                    </ActionMenuIcon>

                    <div
                      className={
                        showAction === topicData?.comnt_id
                          ? "actionContainer"
                          : "actionContainer hideAction"
                      }
                      ref={actionContainerRef}
                    >
                      {topicData?.usr_id === userInfo?.idd?.toString() && (
                        <img
                          src="/assets/images/recycle.svg"
                          alt=""
                          className="icon"
                          onClick={() => handleClickOpen(topicData?.comnt_id)}
                        />
                      )}
                      <ThemeProvider theme={commentDialogTheme}>
                        <Dialog
                          open={open}
                          onClose={handleClose}
                          maxWidth="xs"
                          PaperProps={{
                            style: {
                              boxShadow: "none",
                            },
                          }}
                        >
                          <div className="tpDialogContainer">
                            <p>{t("components:sureDeleteTopic")}</p>
                            <p> {t("components:noUndo")}</p>
                            <div className="btn">
                              <button
                                onClick={handleClose}
                                className="btnCancel"
                              >
                                {t("extras:btnCancel")}
                              </button>
                              <button
                                onClick={() =>
                                  handleDeleteTopic(topicData?.comnt_id)
                                }
                                className="btnExecute"
                              >
                                {t("extras:btnDelete")}
                              </button>
                            </div>
                          </div>
                        </Dialog>
                      </ThemeProvider>
                      <SettingsIcon
                        cmntId={topicData?.comnt_id}
                        usrId={topicData?.usr_id}
                        parent={topicData?.parent}
                        lDispatch={localDispatch}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {showComment === topicData?.comnt_id && (
                <form onSubmit={handleSubmit(postComment)}>
                  <div className="postComment">
                    <textarea
                      {...register("newCmntTxt")}
                      cols="30"
                      rows="4"
                      placeholder={t("components:pHCommentBox")}
                    ></textarea>

                    <div className="btn">
                      <button>
                        {posting ? (
                          <>
                            {t("extras:btnPosting")}
                            <CircularProgress
                              size={15}
                              className="btnProgress"
                            />
                          </>
                        ) : (
                          t("extras:btnPost")
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              )}
              {topicData?.attchmnts?.length >= 1 && (
                <ImageSlider slides={topicData?.attchmnts} />
              )}
              <div className="loadmore">
                {settingLoading && topicId === topicData?.comnt_id && (
                  <CircularProgress color="inherit" size={25} />
                )}
              </div>
              {showSetting === topicData?.comnt_id && (
                <EditPostTopic
                  title={
                    userInfo?.idd?.toString() === topicData?.usr_id &&
                    canEditText(topicData?.time)
                      ? t("components:editTopic")
                      : t("components:editVisibility")
                  }
                  func={t("extras:btnSaveEdit")}
                  noAtt
                  editInx={topicData?.comnt_id}
                  topicTable={topicData?.parent}
                  usrId={topicData?.usr_id}
                  lDispatch={localDispatch}
                  defaultText={topicData?.content}
                  time={topicData?.time}
                />
              )}
              {topicData?.children !== 0 && (
                <TopicComment
                  comntId={topicData?.comnt_id}
                  pChild={topicData?.children}
                  tpDispatch={localDispatch}
                  newSibling={newComment}
                  showMoreAgain={moreComment}
                  tPage={true}
                />
              )}
            </div>
          );
        })
      )}
    </div>
  );
}

export default TopicPage;
