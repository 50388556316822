import { useContext, useState, useRef, useEffect } from "react";

import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";

import "./TabSettings.scss";
import Divider from "../Divider/Divider";
import DeleteTab from "../DeleteTab/DeleteTab";
import axios from "../../utils/Axios";
import ModifyList from "../ModifyList/ModifyList";
import { Context } from "../../utils/Context";
import { AnonContext } from "../AnonymousTabs/State";
import { PriContext } from "../PrivateTabs/State";
import { PerContext } from "../PersonalTopics/State";
import { FgContext } from "../Flagged/State";
import { LkContext } from "../Liked/State";
import { SvContext } from "../Saved/State";
import { useTranslation } from "react-i18next";
import { CircularProgress, ThemeProvider, Tooltip } from "@mui/material";
import { useScrollToSection } from "../../utils/Effects";
import { Done } from "@mui/icons-material";
import ImageCropper from "../ImageCropper/ImageCropper";
import BannerInput from "../BannerInput/BannerInput";
import SettingsCard from "./SettingsCard";
import { TopicTooltipTheme } from "../../utils/MuiThemes";

function TabSettings({ lDispatch, isTabMember, verify }) {
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      resetBnr: false,
      remBnr: false,
    },
  });

  const [warning, setWarning] = useState(false);

  const [start, setStart] = useState(false);
  const [startTransfer, setStartTransfer] = useState(false);
  const [tabName, setTabName] = useState("");
  const [debouncedName, setDebouncedName] = useState(tabName);
  const [nameStatus, setNameStatus] = useState("");
  const [formattedName, setFormattedName] = useState("");
  const [loading, setLoading] = useState(false);

  const { state, dispatch } = useContext(Context);
  const { currentTabName, currentTabId, tabSettings } = state;
  const { anonDispatch } = useContext(AnonContext);
  const { priDispatch } = useContext(PriContext);
  const { perDispatch } = useContext(PerContext);
  const { fgDispatch } = useContext(FgContext);
  const { lkDispatch } = useContext(LkContext);
  const { svDispatch } = useContext(SvContext);
  const settingsRef = useRef(null);

  const [image, setImage] = useState("");
  const [currentPage, setCurrentPage] = useState("chooseImage");
  const [imgAfterCrop, setImgAfterCrop] = useState("");
  const [readyImg, setReadyImg] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["components", "extras"]);

  const tkn = localStorage.getItem("tkn");

  useScrollToSection(settingsRef, "start");

  function base64ImageToBlob(str, fileName) {
    // extract content type and base64 payload from original string
    var pos = str.indexOf(";base64,");
    var type = str.substring(5, pos);
    var b64 = str.substr(pos + 8);

    // decode base64
    var imageContent = atob(b64);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    var buffer = new ArrayBuffer(imageContent.length);
    var view = new Uint8Array(buffer);

    // fill the view, using the decoded base64
    for (var n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }

    // convert ArrayBuffer to Blob
    var blob = new Blob([buffer], { type: type });

    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type,
    });
  }

  useEffect(() => {
    if (imgAfterCrop !== "") {
      const img = base64ImageToBlob(imgAfterCrop, "banner.jpeg");
      setReadyImg(img);
    }
  }, [imgAfterCrop]);
  // Invoked when new image file is selected
  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setCurrentPage("cropImage");
  };

  // Generating Cropped Image When Done Button Clicked
  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );

      const dataURL = canvasEle.toDataURL("image/jpeg");

      setImgAfterCrop(dataURL);
      setCurrentPage("imageCropped");
    };
  };

  // Handle Cancel Button Click
  const onCropCancel = () => {
    setCurrentPage("chooseImage");
    setImage("");
  };

  const handleSettings = async ({
    tablName,
    tablDesc,
    title,
    usrPassChk,
    resetBnr,
    remBnr,
  }) => {
    const formData = new FormData();
    formData.append("id", currentTabId);
    formData.append("name", tablName);
    formData.append("title", title);
    formData.append("desc", tablDesc);
    formData.append("img", readyImg === "" ? null : readyImg);
    formData.append("pass", usrPassChk);
    if (resetBnr !== false) {
      formData.append("resetBnr", resetBnr);
    }
    if (remBnr !== false) {
      formData.append("remBnr", remBnr);
    }
    const url = "/tabsettings";
    const options = {
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
        authorization: `${tkn}`,
      },
      data: formData,
      url,
    };

    try {
      setStart(true);
      const { data } = await axios(options);
      setStart(false);
      if (data.status === true) {
        reset();
        setCurrentPage("chooseImage");

        if (typeof lDispatch === "function") {
          lDispatch({
            type: "TAB_SETTINGS_DONE",
            payload: {
              title: data.title,
              name: data.name,
              desc: data.desc,
              img: data.img,
            },
          });
        }

        if (typeof anonDispatch === "function") {
          anonDispatch({
            type: "TAB_SETTINGS_DONE",
            payload: { fName: currentTabName, cName: data?.name },
          });
        }
        if (typeof priDispatch === "function") {
          priDispatch({
            type: "TAB_SETTINGS_DONE",
            payload: { fName: currentTabName, cName: data?.name },
          });
        }
        if (typeof perDispatch === "function") {
          perDispatch({
            type: "TAB_SETTINGS_DONE",
            payload: { fName: currentTabName, cName: data?.name },
          });
        }
        if (typeof fgDispatch === "function") {
          fgDispatch({
            type: "TAB_SETTINGS_DONE",
            payload: { fName: currentTabName, cName: data?.name },
          });
        }
        if (typeof lkDispatch === "function") {
          lkDispatch({
            type: "TAB_SETTINGS_DONE",
            payload: { fName: currentTabName, cName: data?.name },
          });
        }
        if (typeof svDispatch === "function") {
          svDispatch({
            type: "TAB_SETTINGS_DONE",
            payload: { fName: currentTabName, cName: data?.name },
          });
        }
        dispatch({
          type: "TAB_SETTINGS_DONE",
          payload: { tabName: data?.name },
        });
      }
      enqueueSnackbar(data.msg, {
        variant: data.status === true ? "success" : "error",
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => setTabName(debouncedName), 1000);
    return () => clearTimeout(timer);
  }, [debouncedName]);

  useEffect(() => {
    const handleCheck = async () => {
      const tkn = localStorage.getItem("tkn");
      const config = {
        headers: {
          authorization: `${tkn}`,
        },
      };
      if (tabName?.length >= 3) {
        setLoading(true);
        const { data } = await axios.get(`/tabname?q=${tabName}`, config);
        setLoading(false);
        if (data.status && data.paid_account) {
          setFormattedName(data?.generated?.name);
          setNameStatus("Available");
        }
        if ((data.status || !data.status) && !data.paid_account) {
          setFormattedName(`Use: ${data?.generated?.name}`);
          setNameStatus("Free Account");
        }
        if (!data.status && data.paid_account) {
          setFormattedName(data?.msg);
          setNameStatus("Taken");
        }
      } else {
        setNameStatus("");
        setFormattedName("");
      }
    };

    handleCheck();
  }, [tabName]);

  const handleTransfer = async ({ user, pass }) => {
    const url = "/moving";
    const data = { user, pass, tab: currentTabName };
    const options = {
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
        authorization: `${tkn}`,
      },
      data,
      url,
    };
    try {
      setStartTransfer(true);
      const { data } = await axios(options);
      setStartTransfer(false);
      enqueueSnackbar(data.msg, {
        variant: data.status === true ? "success" : "error",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div ref={settingsRef} className="tsContainer">
      <div className="tsHeading">{t("components:tabSettings")}</div>

      <SettingsCard
        name={t("components:tabDescHead")}
        desc={t("components:tabDesc")}
        click={() => dispatch({ type: "TAB_SETTING", payload: "desc" })}
        tag="desc"
      />
      <form
        onSubmit={handleSubmit(handleSettings)}
        className={
          tabSettings?.includes("desc") ? "tabForm" : "tabForm tabFormCol"
        }
      >
        <div className="tsFields">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <label htmlFor="tablName" className="tsLabels">
              {t("components:changeTabName")}
            </label>
            {loading ? (
              <span>
                <CircularProgress size={10} />
              </span>
            ) : (
              <span>
                {nameStatus === "Available" ? (
                  <span className="catNameStatus">
                    {t("components:available")}
                    <Done
                      fontSize="30"
                      style={{
                        color: "green",
                        marginLeft: "2px",
                      }}
                    />
                  </span>
                ) : nameStatus === "Taken" ? (
                  <span className="catNameStatus">
                    {t("components:taken")}
                    <img
                      src="/assets/images/warning.svg"
                      alt=""
                      style={{ marginLeft: "2px" }}
                    />
                  </span>
                ) : nameStatus === "Free Account" ? (
                  <ThemeProvider theme={TopicTooltipTheme}>
                    <Tooltip title="Free Accounts cannot fully specify unique names for Tabs, unless name starts with '_'.">
                      <img src="/assets/images/warning.svg" alt="" />
                    </Tooltip>
                  </ThemeProvider>
                ) : (
                  ""
                )}
              </span>
            )}
          </div>
          <input
            type="text"
            className="tsInput"
            {...register("tablName")}
            onChange={(e) => setDebouncedName(e.target.value)}
          />
          <div className="formattedName">{formattedName}</div>
        </div>
        <div className="tsFields">
          <label htmlFor="title" className="tsLabels">
            {t("components:changeTitle")}
          </label>
          <input type="text" className="tsInput" {...register("title")} />
        </div>
        <div className="tsFields">
          <label htmlFor="tablDesc" className="tsLabels">
            {t("components:changeDesc")}
          </label>
          <textarea
            name="tablDesc"
            cols="30"
            rows="4"
            {...register("tablDesc")}
            className="tsInput"
          ></textarea>
        </div>
        <div>
          <div>
            {currentPage === "chooseImage" && (
              <BannerInput
                setImage={setImage}
                onImageSelected={onImageSelected}
              />
            )}

            {currentPage === "cropImage" && (
              <>
                {" "}
                <label className="tsLabels">{t("components:cropImage")}</label>
                <ImageCropper
                  image={image}
                  onCropDone={onCropDone}
                  onCropCancel={onCropCancel}
                />
              </>
            )}

            {currentPage === "imageCropped" && (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "200px",
                  top: "6px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    height: "fit-content",
                    left: "0",
                    right: "0",
                  }}
                >
                  <img
                    alt=""
                    src={imgAfterCrop}
                    style={{ width: "100%", height: "fit-content" }}
                  />
                </div>
                <div className="cropperBtnContainer">
                  <div
                    className="crpBtn"
                    onClick={() => {
                      setCurrentPage("cropImage");
                    }}
                  >
                    {t("components:crop")}
                  </div>

                  <div
                    className="crpBtn"
                    onClick={() => {
                      setCurrentPage("chooseImage");
                      setImage("");
                    }}
                  >
                    {t("components:newImage")}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="tsCheckCont">
          <input
            type="checkbox"
            value={"reset"}
            id="resetBnr"
            className="tsInput"
            {...register("resetBnr")}
          />
          <label htmlFor="resetBnr">{t("components:resetBannerImg")}</label>
        </div>
        <div className="tsCheckCont">
          <input
            type="checkbox"
            value={"remove"}
            id="remBnr"
            className="tsInput"
            {...register("remBnr")}
          />
          <label htmlFor="remBnr">{t("components:removeBannerImg")}</label>
        </div>
        <div className="tsFields">
          <label htmlFor="usrPassChk" className="tsLabels">
            {t("extras:btnSave")} <span className="astTs">*</span>
          </label>
          <div className="passFieldWrapper">
            <input
              name="usrPassChk"
              id="usrPassChk"
              type="password"
              placeholder={t("components:enterCurrentPass")}
              className="tsInput"
              {...register("usrPassChk")}
            />
          </div>
        </div>
        <div className="tsBtn">
          <button type="submit">
            {t("extras:btnSave")}
            {start && <CircularProgress size={15} className="btnProgress" />}
          </button>
        </div>
      </form>

      <SettingsCard
        name={t("components:modifyPartHead")}
        desc={t("components:modifyPart")}
        click={() => dispatch({ type: "TAB_SETTING", payload: "mod" })}
        tag="mod"
      />

      <ModifyList />
      {tabSettings?.includes("mod") && (
        <div
          style={{
            height: "40px",
            width: "100%",
          }}
        ></div>
      )}
      <SettingsCard
        name={t("components:transferTabHead")}
        desc={t("components:transferTab")}
        click={() => dispatch({ type: "TAB_SETTING", payload: "transfer" })}
        tag="transfer"
      />

      <form
        onSubmit={handleSubmit(handleTransfer)}
        className={
          tabSettings?.includes("transfer") ? "tabForm" : "tabForm tabFormCol"
        }
      >
        <div className="tsFields">
          <label htmlFor="user" className="tsLabels">
            {t("components:email")}
          </label>
          <input
            type="email"
            className="tsInput"
            {...register("user")}
            onClick={() => {
              setWarning(true);
            }}
          />
        </div>
        <div className="tsFields">
          <label htmlFor="usrPassChk" className="tsLabels">
            {t("extras:btnSave")} <span className="astTs">*</span>
          </label>
          <div className="passFieldWrapper">
            <input
              type="password"
              className="tsInput"
              {...register("pass")}
              onClick={() => {
                setWarning(true);
              }}
              placeholder={t("components:enterCurrentPass")}
            />
          </div>
        </div>
        {warning && <p className="tfWarning">{t("components:tfWarning")}</p>}
        <div className="tsBtn">
          <button type="submit">
            {t("extras:btnTransfer")}{" "}
            {startTransfer && (
              <CircularProgress size={15} className="btnProgress" />
            )}
          </button>
        </div>
      </form>
      <SettingsCard
        name={isTabMember ? t("components:leaveTab") : t("extras:btnGO")}
        desc={
          isTabMember
            ? t("components:exitTab")
            : t("components:verifyMembership")
        }
        click={() => dispatch({ type: "TAB_SETTING", payload: "leave" })}
        tag="leave"
      />
      <>
        <div
          className={
            tabSettings?.includes("leave")
              ? "dtContainer"
              : "dtContainer dtContainerCol"
          }
        >
          {isTabMember ? (
            <div
              onClick={() => {
                lDispatch({ type: "SHOW_MODAL" });
              }}
              className="tsLeave"
            >
              <img src={`/assets/images/leaveGrey.svg`} alt="leave" />
              {t("extras:btnLeave")}
            </div>
          ) : (
            <div onClick={verify} className="tsLeave">
              <img src={`/assets/images/leaveGrey.svg`} alt="leave" />
              {t("extras:btnGO")}
            </div>
          )}
        </div>
      </>
      <SettingsCard
        name={t("components:deleteTabL")}
        desc={t("components:deleteTab")}
        click={() => dispatch({ type: "TAB_SETTING", payload: "delete" })}
        tag="delete"
      />
      <DeleteTab />
      <div className="tsDivider" style={{ marginTop: "0", paddingTop: "0" }}>
        <Divider width="100%" />
      </div>
    </div>
  );
}

export default TabSettings;
